import * as React from "react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import Section1 from "../components/Section1";
import Section2 from "../components/Section2";
import Section7 from "../components/Section7";
import Footer from "../components/Footer";

const IndexPage = () => {
  return (
    <Layout>
      <div className="container-fluid p-0">
        <Header />
        <Section1 />
        <Section2 />
        <Section7 />
        <Footer />
      </div>
    </Layout>
  )
}

export default IndexPage

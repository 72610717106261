import React from "react";
import modalfoot from "../assets/images/tribu.png";

function Section7() {
  return (
    <section className="section_7 position-absolute d-flex justify-content-center align-items-center modal">
      <div className="col-6 col-offset-3 bgmodal">
        <div className="modalheader text-center">¡Tu registro fue exitoso!</div>
        <div className="modalbody text-center">Bienvenido a una nueva forma <br /> de gestionar todo lo relacionado <br /> a tu urbanización.</div>
        <div className="modalfoot">
          <img src={modalfoot} alt="Tribu"/>
        </div>
      </div>
    </section>
  );
}

export default Section7;
